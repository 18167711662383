import EmptyLayout from '@/layouts/empty-layout.vue'
import { CustomRouteRecordRaw } from 'vue-router'

export const clientRoutes: CustomRouteRecordRaw = {
  path: 'client',
  name: 'client',
  component: EmptyLayout,
  meta: {
    title: '企业管理',
    iconfont: 'icon-apartment_black_24dp',
    requiredPermission: 'COMPANY_MODEL',
  },
  children: [
    // 企业管理员列表
    {
      path: 'admin-list',
      name: 'adminList',
      component: () => import('@/views/client/admin-list.vue'),
      meta: {
        title: '账号设置',
        requiredPermission: 'ADMIN_VIEW',
      },
    },
    // 企业列表
    {
      path: 'company-list',
      name: 'companyList',
      component: () => import('@/views/client/company-list.vue'),
      meta: {
        title: '用工单位',
        requiredPermission: 'COMPANY_VIEW',
      },
    },
    // 工作地点列表
    {
      path: 'work-place-list',
      name: 'workPlaceList',
      component: () => import('@/views/client/work-place-list.vue'),
      meta: {
        title: '工作地点列表',
        requiredPermission: 'COMPANY_VIEW',
      },
    },
  ],
}
