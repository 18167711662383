import { ExtractPropTypes, PropType } from 'vue'
import type { InputNumberProps } from 'ant-design-vue/es/input-number'

export const rangeMoneyInputProps = {
  // 双向数据绑定的该组件的值
  value: {
    type: Array as PropType<string[]>,
    default: () => ([])
  },
}

export type RangeMoneyInputProps = Partial<ExtractPropTypes<typeof rangeMoneyInputProps>> & InputNumberProps