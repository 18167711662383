<template>
  <basic-modal
    v-bind="bindModalAttrs"
    @ok="$emit('ok')"
    @cancel="$emit('cancel')"
  >
    <basic-form
      ref="formRef"
      v-bind="bindFormProps"
    />
    <template
      #footer
    >
      <div class="flex justify-between">
        <div>
          <slot name="left">
            <a-space>
              <a-dropdown
                v-if="draftKey"
                placement="top"
              >
                <a-button>
                  草稿
                  <down-outlined />
                </a-button>
                <template #overlay>
                  <a-menu>
                    <a-menu-item
                      key="save"
                      @click="handleSaveDraft"
                    >
                      立即保存
                    </a-menu-item>
                    <a-menu-divider />
                    <a-menu-item
                      v-for="(item, index) in (getDraftList as DraftItem[])"
                      :key="index"
                      @click="handleLoadDraft(item)"
                    >
                      {{ item.title }} {{ dateFromNow(item.saveAt) }}
                    </a-menu-item>
                  </a-menu>
                </template>
              </a-dropdown>
            </a-space>
          </slot>
        </div>
        <div>
          <a-space>
            <template v-if="actions.length">
              <a-button
                v-for="action in actions"
                v-bind="action"
                :key="action.label"
                @click="handleConfirm(action)"
              >
                {{
                  action.label
                }}
              </a-button>
            </template>
            <template v-else>
              <a-button
                :type="bindModalAttrs.cancelType"
                @click="handleCancel"
              >
                {{ bindModalAttrs.cancelText }}
              </a-button>
              <a-button
                :type="bindModalAttrs.okType"
                :loading="attrs.confirmLoading"
                @click="() => handleConfirm()"
              >
                {{ bindModalAttrs.okText }}
              </a-button>
            </template>
          </a-space>
        </div>
      </div>
    </template>
  </basic-modal>
</template>

<script lang="ts" setup>
import { computed, ref, unref } from 'vue'
import { pick } from 'lodash-es'
import { modalFormProps } from './props'
import { useMessage } from '@/hooks/message'
import { DraftItem, useDraftBox } from '@/hooks/draft-box'
import { Modal } from 'ant-design-vue/es'
import { dateFromNow } from '@/utils/date'

/* data 数据 */
const attrs = useAttrs()
const props = defineProps(modalFormProps)

const { saveDraft, getDraftList, setKey } = useDraftBox()
type EmitEvents = {
  (e: 'cancel'): void
  (e: 'ok'): void
}
const emits = defineEmits<EmitEvents>()
const loading = ref(false)
const formRef = ref<IForm.Expose>()

const bindModalAttrs = computed(() => ({
  maskClosable: false,
  ...props.modalProp,
  ...pick(props, ['title', 'width', 'visible', 'okText']),
  confirmLoading: unref(loading),
}))


const bindFormProps = computed(() => ({
  actionable: false,
  ...props.formProp,
  ...pick(props, ['schemas', 'model']),
} as IForm.Props))

/* 逻辑 */
defineExpose({
  formRef,
})
watch(
  () => props.draftKey,
  key => {
    setDrafList(key)
  },
)

/* methods 方法 */
/* 设置草稿 */
function setDrafList(key) {
  if (key) {
    setKey(key)
  }
}

function handleSaveDraft() {
  const model = toRaw(unref(formRef.value?.formModel))
  saveDraft(model!)
}

function handleLoadDraft(item: DraftItem) {
  const { record, title, saveAt } = item
  Modal.confirm({
    title: `使用 ${saveAt} 保存的草稿【${title}】`,
    content: '草稿将会覆盖现在的表单项，请谨慎使用!',
    okText: '确定使用',
    onOk: async () => {
      await formRef.value?.setFormModel(record)
      useMessage.success('使用草稿成功')
    },
    zIndex: 1002,
  })
}

async function handleConfirm(action?: IModalForm.Action) {
  try {
    loading.value = true
    const values = await formRef.value?.submit()
    if (values) {
      // 兼容 field 中传入点字符串分隔的对象，自动结构为对应的对象
      const actionApi: any = action ? action.click : props.api
      const resultTips = await actionApi(values)
      useMessage.success(resultTips || '操作成功')
      if (props.successCallback) {
        props.successCallback(values)
      }
      formRef.value?.resetFields()
      emits('ok')
    }
  } finally {
    loading.value = false
  }
}

function handleCancel() {
  loading.value = false
  formRef.value?.resetFields()
  emits('cancel')
}

</script>
