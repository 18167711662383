<template>
  <div>
    <Cascader
      :value="value"
      :options="options"
      :load-data="loadData"
      placeholder="请选择"
      change-on-select
      @update:value="handleChange"
    />
  </div>
</template>
<script lang="ts" setup name="region-picker">
import { ref } from 'vue'
import { Cascader } from 'ant-design-vue'
import { province, city, county } from './data'
import { regionSelectProps } from './props'
type EmitEvents = {
  (e:'update:value', value):void
}
const emits = defineEmits<EmitEvents>()
const props = defineProps(regionSelectProps)
const value = computed(() => props.value)

const options = ref(province.map(p => ({
  value: p.name,
  label: p.name,
  key: p.id,
  isLeaf: false,
})))

const loadData = selectedOptions => {
  const { length } = selectedOptions
  const targetOption = selectedOptions[length - 1]
  targetOption.loading = true
  switch (length) {
    case 1:
      targetOption.children = city[targetOption.key].map(i => ({
        value: i.name,
        label: i.name,
        key: i.id,
        isLeaf: false,
      }))
      break
    case 2:
      targetOption.children = county[targetOption.key].map(i => ({
        value: i.name,
        label: i.name,
        key: i.id,
      }))
      break
    default:
      break
  }
  targetOption.loading = false
  options.value = [...options.value]
}

function handleChange(value) {
  emits('update:value', value)
}
</script>
