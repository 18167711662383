import { useSiteConfig } from '@/hooks/site-config'
import { useTimeoutFn } from '@vueuse/core'
import { ComputedRef } from 'vue'
import { PaginationProps, PaginationConfig } from 'ant-design-vue/es/pagination'
import { cloneDeep, isBoolean, get, isFunction } from 'lodash-es'

const { table } = useSiteConfig
type ActionParam = {
  setLoading: (loading: boolean) => void;
  setPagination: (PaginationConfig: PaginationConfig) => void;
}

export function useDataSource(
  propsRef: ComputedRef<IGridTable.Props>,
  paginationRef: ComputedRef<PaginationProps | boolean>,
  { setLoading, setPagination }: ActionParam,
) {
  let searchParam: Recordable | undefined = {} // 搜索条件的保存
  const rawDataSourceRef = ref<any[]>([])
  const dataSourceRef = computed(() => rawDataSourceRef.value)
  const sortField = ref('')

  const totalRef = ref(0)
  watch(
    () => totalRef.value,
    total => setPagination({ total }),
  )
  watch(() => propsRef.value.dataSource, () => {
    fetch()
  })

  onMounted(() => {
    useTimeoutFn(() => {
      propsRef.value.immediate && fetch()
      // eslint-disable-next-line no-magic-numbers
    }, 10)
  })

  const setSortField = field => {
    sortField.value = field
  }

  async function fetch() {
    const { api, dataSource, getListApiParams } = unref(propsRef)
    if (dataSource) {
      rawDataSourceRef.value = dataSource

    }
    if (!api) {
      return
    }
    try {
      let pageParam: Recordable = {}
      if (!isBoolean(unref(paginationRef))) {
        const { current = 1, pageSize = table.defaultPageSize } = unref(paginationRef) as PaginationProps
        pageParam[table.pageField] = current
        pageParam[table.pageSizeField] = pageSize
      }

      const requestParam = {
        ...pageParam,
        ...getListApiParams,
        ...(searchParam ?? {}),
        sortField: sortField.value
      }

      setLoading(true)
      const fetchApi = isFunction(api) ? api : api.getList
      const res = await fetchApi!(requestParam)
      const data = get(res, table.resListField)
      rawDataSourceRef.value = cloneDeep(data)
      totalRef.value = get(res, table.resTotalField)
    } catch (error) {
      rawDataSourceRef.value = []
      totalRef.value = 0
    } finally {
      setLoading(false)
    }
  }

  async function reload() {
    await fetch()
  }

  function search(newSearchParam?: Recordable) {
    searchParam = newSearchParam
    return new Promise((resolve, reject) => {
      try {
        setPagination({ current: 1 }) // 点击搜索，将页码重置为 1
        const ret = fetch()
        return resolve(ret)
      } catch (err) {
        return reject(err)
      }
    })
  }

  return {
    reload,
    search,
    setSortField,
    dataSourceRef,
    rawDataSourceRef,
    totalRef,
  }
}
