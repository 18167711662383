<template>
  <div class="flex">
    <input
      v-model="rangeDetail.min"
      class="ant-input flex-grow"
      style="flex-basis: 150px; text-align: center"
      placeholder="请输入最低薪资"
      @input="nativeInput"
    >
    <input
      class="ant-input ant-input-disabled flex-shrink-0"
      style="width: 30px; border-left: 0; pointer-events: none; background-color: #fff"
      placeholder="~"
      disabled
    >
    <input
      v-model="rangeDetail.max"
      class="ant-input flex-grow"
      style="flex-basis: 150px; text-align: center"
      placeholder=" 请输入最高薪资"
      @input="nativeInput"
    >
    <div class="ant-input-group-addon flex-grow leading-7">
      元
    </div>
  </div>
</template>

<script lang="ts" setup name="input-money">
import { rangeMoneyInputProps } from './props'
import { watch, reactive } from 'vue'


/* data 数据 */
const props = defineProps(rangeMoneyInputProps)
type EmitEvents = {
  (e: 'update:value', value): void
}
const emits = defineEmits<EmitEvents>()
const rangeDetail = reactive({
  min: '',
  max: ''
})

/* logics 逻辑 */
watch(() => ({ ...rangeDetail }), (...params) => {

  const rangeMin = rangeDetail.min
  const rangeMax = rangeDetail.max
  const newRangeMin = rangeDetail.min?.replace(/[^0-9.]/gi, '')
  const newRangeMax = rangeDetail.max?.replace(/[^0-9.]/gi, '')

  if (rangeMin !== newRangeMin || rangeMax !== newRangeMax) {
    emits('update:value', [newRangeMin, newRangeMax])
  }

}, {
  immediate: false,
})
watch(() => props.value, () => {
  rangeDetail.min = props.value?.[0]
  rangeDetail.max = props.value?.[1]
}, {
  immediate: true,
})

/* methods 方法 */
function nativeInput() {
  emits('update:value', [rangeDetail.min, rangeDetail.max])
}
</script>
