<style lang="less" scoped>
.tools {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;

  > * {
    margin-left: 10px;
  }
}
</style>

<template>
  <div class="flex flex-row-reverse justify-between items-center">
    <div class="flex justify-end">
      <div>
        <a-space>
          <div
            v-if="props.listTitleIsShow && props.title"
            class="inline align-middle text-base"
          >
            {{ `${props.title}列表` }}
          </div>
          <a-dropdown v-if="multiActions?.length > 0">
            <a-button
              type="primary"
              ghost
            >
              批量操作
              <down-outlined />
            </a-button>
            <template #overlay>
              <a-menu>
                <a-menu-item
                  v-for="action in multiActions"
                  :key="action.label"
                  :disabled="!multiActionable"
                  @click="() => handleClickAction(action)"
                >
                  {{ action.label }}
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
          <a-button
            v-for="action in actions"
            v-bind="action"
            :key="action.label"
            @click="() => handleClickAction(action)"
          >
            {{ action.label }}
          </a-button>
        </a-space>
      </div>
      <div class="tools">
        <a-button
          v-if="props.creatable"
          type="primary"
          @click="handleCreate"
        >
          {{ createText }}
        </a-button>
        <a-divider type="vertical" />
        <a-tooltip title="刷新">
          <redo-outlined
            style="font-size: 16px"
            @click="() => context.reload()"
          />
        </a-tooltip>
        <span v-if="props.exportable">
          <table-exporter
            :export-handler="props.exportHandler"
            :disabled-current-export="disabledCurrentExport"
          />
        </span>
        <span>
          <column-setting />
        </span>
        <a-tooltip :title="getTitle">
          <fullscreen-exit-outlined
            v-if="isFullscreen"
            style="font-size: 16px"
            @click="toggle"
          />
          <fullscreen-outlined
            v-else
            style="font-size: 16px"
            @click="toggle"
          />
        </a-tooltip>
      </div>
    </div>
    <div
      v-if="props.title === '信息人登记表'"
      class="flex items-center"
    >
      <div class="text-base">
        信息人登记表
      </div>
      <div class="ml-4 pl-[10px] pr-9 flex items-center bg-else-E5F1FE text-xs text-common-theme leading-8 rounded">
        <component :is="getAntIcon('info-circle-outlined')" />
        <div class="ml-2">
          配置信息登记表，您可以复制链接/下载二维码后，分享给员工填写信息，员工提交后，可在【入离职管理】中查看
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useUserStore } from '@/store'
import { useTableContext } from '../hooks/context'
import TableExporter from './table-exporter.vue'
import ColumnSetting from './column-setting.vue'
import { getAntIcon } from '@/utils/icon'

const userStore = useUserStore()
const context = useTableContext()
const { toggle, isFullscreen } = useFullscreen(context.wrapLlRef)
type EmitEvents = {
  (e: 'create'): void;
  (e: 'setFullscreen', isFullscreen: boolean): void;
};
defineProps({
  disabledCurrentExport: Boolean,
})
const emits = defineEmits<EmitEvents>()
const props = computed(() => context.getProps().value)
const multiActionable = computed(() => context.getSelectionKeys().length > 0)
const validActions = computed(() =>
  unref(props.value.tableActions?.filter(a =>
    a.isShow?.(props.value?.activeTabKey) !== false
        && userStore.hasPermission(a.requiredPermission)) || []))
const multiActions = computed(() =>
  unref(validActions.value.filter(a => a.needSelection)))
const actions = computed(() =>
  unref(validActions.value.filter(a => !a.needSelection)))
const createText = computed(() => props.value.createText ?? `新增${props.value.title}`)
const getTitle = computed(() => (isFullscreen.value ? '退出全屏' : '全屏'))

watch(isFullscreen, () => {
  emits('setFullscreen', isFullscreen.value)
})

function handleCreate() {
  emits('create')
}

async function handleClickAction(action: IGridTable.Action) {
  if (action.needSelection) {
    await action.click(
      toRaw(unref(context.getSelectionKeys())),
      toRaw(unref(context.getSelectionRows()))
    )
  } else {
    await action.click()
  }
  action.needReload && context.reload()
}
</script>
