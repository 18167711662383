<template>
  <Input
    v-bind="$attrs"
    :value="props.value"
    @update:value="handleChange"
  />
</template>

<script lang="ts" setup>
import { Input } from 'ant-design-vue'
const props = defineProps<{ value?: string | number }>()
const emits = defineEmits(['update:value', 'blur'])

function handleChange(value) {
  const v = value.replace(/[^\w]/gi, '')
  emits('update:value', v)
}
</script>
