
import { cloneDeep } from 'lodash-es'

/* 解析点拼接的字符串对象 */
export function parseDotStrObjToObj(dotStrObj: Recordable): Recordable {
  const resultObj = {}
  Object.keys(dotStrObj).forEach(key => {
    let keys = key.split('.')
    keys.reduce((reduceObj, curValue, currentIndex) => {
      reduceObj[curValue] = currentIndex === keys.length - 1 ? dotStrObj[key] : reduceObj[curValue] || { }
      return reduceObj[curValue]
    }, resultObj)
  })
  return resultObj
}

/* 将普通对象扁平化，使用点符合拼接对象层级 */
export function transformObjToDotStrObj(targetObj: Recordable): Recordable {
  targetObj = cloneDeep(targetObj)
  const resultObj = {}
  function transform(currentObj, preKeys) {
    Object.keys(currentObj).forEach(key => {
      if (!['[object Object]', '[object Array]'].includes(Object.prototype.toString.call(currentObj[key]))) {
        resultObj[[...preKeys, key].join('.')] = currentObj[key]
      } else {
        transform(currentObj[key], [...preKeys, key])
      }
    })
  }
  transform(targetObj, [])
  return resultObj
}

export function deleteEmptyProp(val: Recordable | any[], predicate = v => Boolean(v)) {
  const data = Array.isArray(val) ? val : val
  return Object.keys(data).reduce(
    (acc, key) => {
      const value = data[key]
      if (predicate(value)) {
        acc[key] = typeof value === 'object' ? deleteEmptyProp(value, predicate) : value
      }
      return acc
    },
    Array.isArray(val) ? [] : {}
  )
}