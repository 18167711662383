<!-- maxCount属性会失效 -->

<template>
  <div>
    <a-upload
      v-bind="bindAttrs"
      :before-upload="handleBeforeUpload"
      @change="handleChange"
      @preview="handlePreview"
    >
      <div v-if="files.length < maxNum">
        <div
          :style="{width: `${width}px`}"
          class="p-4 text-common-blue border border-common-blue border-dashed"
        >
          <upload-outlined />
          {{ `点击或直接将文件拖拽到此处进行上传，支持格式.xlsx、.xls` }}
        </div>
      </div>
    </a-upload>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, unref, useAttrs } from 'vue'
import { useMessage } from '@/hooks/message'
import { UploadOutlined } from '@ant-design/icons-vue'
import { useEnv } from '@/hooks/env'
import { useUserStore } from '@/store'
import { previewFromUrl } from '@/utils/file'
import { pick, omit } from 'lodash-es'
import { UploadFile, Upload } from 'ant-design-vue'
import { getExcelOriginCell, getSheetHeaderAndData, getSheetHeaderAndData2 } from '@/utils/xlsx'

const attrs = useAttrs()
type EmitEvents = {
  (e: 'update:value', params): void
  (e: 'parse', params): void
}

const emits = defineEmits<EmitEvents>()
const props = defineProps({
  value: {
    type: Array,
    default: () => [],
  },
  // 文件最大大小
  maxSize: {
    type: Number,
    default: 2,
  },
  // 最大文件数
  maxNum: {
    type: Number,
    default: 1,
  },

  /* 宽度 */
  width: {
    type: Number,
    default: 0
  },

  /* 文本键映射 */
  /*
    例:
    const textKeyMap = {
      '*姓名': 'realname',
      '*手机号': 'mobile',
      '*身份证号': 'idcard',
      用工单位: 'companyName',
      员工状态: 'status',
      入职日期: 'enteredAt',
    }
   */
  textKeyMap: {
    type: Object,
    default: () => ({})
  }
})

// upload 组件值，设置该值，使得 upload 组件为受控组件。上传的状态及图片的显示，由 files 控制
const files = ref<any>([])

// 获取上传接口调用的请求头
const getHeaders = computed(() => ({
  ...pick(attrs, 'headers'),
  Authorization: `Bearer ${useUserStore().token}`,
}))

const getParams = computed(() => ({
  ...pick(attrs, 'data'),
}))

const bindAttrs = computed(() => ({
  fileList: unref(files),
  action: useEnv.uploadApiUrl,
  headers: unref(getHeaders),
  data: unref(getParams),
  accept: '.xlsx,.xls',
  ...omit(attrs, ['headers', 'data']),
}))

// 上传前拦截
function handleBeforeUpload(file: File) {
  const { size, name } = file
  const { maxSize, maxNum } = props

  if (maxSize && maxSize * 1024 * 1024 < size) {
    useMessage.error(`只能上传不超过${maxSize}M的文件`)
    return Upload.LIST_IGNORE
  }

  if (maxNum && maxNum <= files.value?.length) {
    useMessage.error(`最多上传${maxNum}个文件`)
    return Upload.LIST_IGNORE
  }

  const acceptArr = ['xlsx', 'xls']
    .map(i => (i.startsWith('.') ? i.slice(1) : i))
  if (acceptArr.length > 0 && !new RegExp(`\\.(${acceptArr.join('|')})$`, 'i').test(name)) {
    useMessage.error(`只能上传${acceptArr.join(',')}格式的文件`)
    return Upload.LIST_IGNORE
  }
  return false
}

// 上传完成，数据整理
async function handleChange(e) {
  const { file, fileList } = e as { file: UploadFile, fileList: UploadFile[] }
  files.value = fileList
  if (file.status !== 'removed') {
    const sheetList = await getExcelOriginCell(file as any) // 获取 excel 表所有 sheet 所有单元格数据
    const { dataList, headerColumns, dataSourceList } = getSheetHeaderAndData(sheetList[0], props.textKeyMap)
    const andDataDetail = getSheetHeaderAndData2(sheetList[0], props.textKeyMap)


    emits('parse', andDataDetail)

    if (!dataList.length) {
      useMessage.error('表格不能为空，请填入信息')
    } else if (dataList.length > 500) {
      useMessage.error('最多导入500条数据')
    } else {
      emits('update:value', dataList)
    }
    // emits('update:value', filePaths.value)
  }
}

// 预览功能，解决 ant-design 默认新开页面，打开 base64 数据造成的 chrome 安全警告问题，Not allowed to navigate top frame to data URL:
function handlePreview(file: UploadFile) {
  if (file.response) { // 新上传的文件
    previewFromUrl(file.response.data.url)
  } else { // 数据回填的文件
    previewFromUrl(file.url!)
  }
}
</script>
