<style scoped lang="less">
.left-size {
  display: flex;
}

.logo {
  img {
    height: 24px;
    width: 120px;
  }
}

.actions > * :hover {
  background-color: #F6F6F6;
}
</style>

<template>
  <div class="flex items-center justify-between h-full mx-2">
    <div class="flex">
      <div class="ml-2 text-base">
        <a-breadcrumb>
          <a-breadcrumb-item
            v-for="routeConfig in parentPath"
            :key="routeConfig.name"
          >
            <router-link :to="{ name: routeConfig.name }">
              {{ routeConfig.meta.title }}
            </router-link>
          </a-breadcrumb-item>
          <a-breadcrumb-item>{{ useRoute().meta.title }}</a-breadcrumb-item>
        </a-breadcrumb>
        <!-- {{ user?.client.company }} -->
      </div>
    </div>
    <div class="flex actions">
      <div>
        <user-setting />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { nameToRouterConfigMap } from '@/router'
import UserSetting from './user-setting.vue'

import { useRouter, onBeforeRouteUpdate, useRoute } from 'vue-router'

// 因为 vue-router 内部使用 Provide 将 router 对象注入到 vue 实例中，useRouter 需要使用 inject 获取 router 实例
// 而 inject 需要用在 setup 顶层，
const router = useRouter()
const parentPath = ref<Recordable[]>([])

function updateBreadcrumb(to) {
  parentPath.value = []
  const currentRoute = unref(to || router?.currentRoute)
  const currentRouteName = currentRoute.name as string
  let currentRouteConfig = nameToRouterConfigMap[currentRouteName]

  while (currentRouteConfig.meta.parentNameForBreadcrumb) {
    currentRouteConfig
      = nameToRouterConfigMap[currentRouteConfig.meta.parentNameForBreadcrumb]
    parentPath.value.unshift(currentRouteConfig)
  }
}

onBeforeRouteUpdate(to => {
  updateBreadcrumb(to)
})
updateBreadcrumb(null)
</script>
