import { PropType, ExtractPropTypes } from 'vue'
import type { UploadProps as AUploadProps } from 'ant-design-vue/es/upload'

export const uploadProps = {
  // 文件最大大小
  maxSize: {
    type: Number,
    default: 2,
  },

  /* 宽度 */
  width: {
    type: Number,
    default: 400
  },

  value: {
    type: [Array, String] as PropType<string[] | string>,
    default: '',
  },

  acceptType: {
    type: String as PropType<'img' | 'file' | 'zip' | 'doc'>,
    default: 'file',
  },

  /* 限制文案 */
  desc: {
    type: String,
    default: ''
  }
}

export type UploadProps = Partial<ExtractPropTypes<typeof uploadProps>> & AUploadProps