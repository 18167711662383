<template>
  <icon-font :type="name" />
</template>
<script lang="ts" setup>
import { createFromIconfontCN } from '@ant-design/icons-vue'

const IconFont = createFromIconfontCN({
  scriptUrl: 'https://at.alicdn.com/t/c/font_3346607_q7208viqtb9.js',
})

defineProps({
  name: {
    type: String,
    required: true,
  },
})

</script>
