<!-- 1. 未监听dataSource 属性 -->

<template>
  <div>
    <div
      v-if="propsRef.searchSchemas?.length"
      class="px-2 py-2  bg-white"
      style="border-bottom: solid 1px #EFEFEF;"
    >
      <basic-form
        ref="baseFormRef"
        :is-search-form="true"
        :schemas="propsRef.searchSchemas"
        :collapsible="true"
        :reset-do-submit="true"
        submit-text="搜索"
        @submit="search"
      />
    </div>
    <div
      ref="tableWrapElRef"
      class="px-2 pt-1 bg-white"
    >
      <div
        v-if="bindAttrs.headerIsShow"
        class="my-2"
      >
        <table-header
          :disabled-current-export="disabledCurrentExport"
          @create="handleCreate"
          @setFullscreen="setFullscreen"
        />
      </div>
      <a-table
        v-bind="bindAttrs"
        ref="tableRef"
        sticky
      >
        <template
          v-for="slot in Object.keys(slots)"
          #[slot]="data"
        >
          <slot
            :name="slot"
            v-bind="data || {}"
          />
        </template>
      </a-table>
    </div>
  </div>
</template>

<script setup lang="ts">
import { createTableContext } from './hooks/context'
import { useDataSource } from './hooks/datasource'
import { useLoading } from './hooks/loading'
import { gridTableProps } from './props'
import { usePagination } from './hooks/pagination'
import { useColumn } from './hooks/column'
import { useSelection } from './hooks/selection'
import { useScroll } from './hooks/scroll'
import { omit } from 'lodash-es'
import BasicForm from '../form/basic-form.vue'
import { useAction } from './hooks/action'
import { useSiteConfig } from '@/hooks/site-config'
// import {Table} from 'ant-design-vue'

const tableWrapElRef = ref()
const tableRef = ref()
const baseFormRef = ref()
const attrs = useAttrs()
const slots = useSlots()
const props = defineProps(gridTableProps)
const dynamicProps = ref<Partial<IGridTable.Props>>()
const propsRef = computed(() => ({
  ...attrs,
  ...props,
  ...unref(dynamicProps),
} as IGridTable.Props))

const { setLoading, getLoading } = useLoading()
const { paginationRef, setPagination } = usePagination(propsRef)
const { selectedKeysRef, selectedRowsRef, resetSelection, selectionRef } = useSelection(propsRef)
const { reload, search, setSortField, rawDataSourceRef, dataSourceRef, totalRef } = useDataSource(propsRef, paginationRef, {
  setLoading,
  setPagination,
})
const { handleCreate, handleEdit, handleDel } = useAction(propsRef, reload)
const { columnsRef, setColumns, getColumns } = useColumn(
  propsRef,
  paginationRef,
  rawDataSourceRef,
  {
    handleEdit,
    handleDel,
  }
)
const { scrollRef, setFullscreen } = useScroll(propsRef, tableRef!, columnsRef)

const bindAttrs = computed(() => ({
  rowKey: 'id',
  ...omit(unref(propsRef), 'title'),
  loading: unref(getLoading),
  columns: unref(columnsRef),
  rowSelection: unref(selectionRef),
  pagination: unref(paginationRef),
  dataSource: unref(dataSourceRef),
  scroll: unref(scrollRef),
  onChange: (pagination, _filters, sorter) => {
    if (Object.keys(sorter).length) {
      setSortField(sorter.field)
      pagination.pageSize = useSiteConfig.table.exportMaxNum
    }
    setPagination(pagination)
    reload()
  },
}))

function setProps(newProps: Partial<IGridTable.Props>) {
  dynamicProps.value = {
    ...unref(dynamicProps),
    ...newProps,
  }
}

const exposeData: IGridTable.Expose = {
  wrapLlRef: tableWrapElRef,
  reload,
  setProps,
  getProps: () => propsRef,
  setLoading,
  getColumns,
  setColumns,
  setPagination,
  getSearchParams: () => unref(baseFormRef.value.formModel),
  getDataSource: () => unref(dataSourceRef),
  getTotal: () => unref(totalRef),
  resetSelection,
  getSelectionKeys: () => unref(selectedKeysRef),
  getSelectionRows: () => unref(selectedRowsRef),
}
defineExpose(exposeData)
createTableContext(exposeData)
</script>
