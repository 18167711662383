import { useSiteConfig } from '../../../hooks/site-config'
import { computed, unref, ref, Ref, ComputedRef, h } from 'vue'
import { cloneDeep, isFunction, isBoolean, isString } from 'lodash-es'
import RowAction from '../components/row-action.vue'
import { Tag, Image } from 'ant-design-vue'
import { PaginationProps } from 'ant-design-vue/es'
import { useAppStore } from '@/store'
const { table } = useSiteConfig

export declare type GetColumnsParm = { ignoreIndex?: boolean; ignoreAction?: boolean; ignoreHidden?: boolean }

export function useColumn(
  props: ComputedRef<IGridTable.Props>,
  paginationRef: ComputedRef<PaginationProps | boolean>,
  rawDataSourceRef: Ref<any[]>,
  actions: { handleEdit: Fn; handleDel: Fn },
) {
  const rawColumnsRef = ref(unref(props).columnSchemas)
  const columnsRef = computed(() => unref(allColumnsRef!)?.filter(column => !column.defaultHidden))
  const allColumnsRef = computed<IGridTable.ColumnProps[]>(() => {
    const {
      indexable,
      editable,
      deleteable,
      rowActions: rawRowActions = [],
    } = unref(props)

    let columns = (cloneDeep(unref(rawColumnsRef)) as IGridTable.ColumnProps[]).map(getAntColumn)

    if (!columns) {
      return []
    }

    // 索引列
    if (indexable) {
      columns.unshift({
        width: 60,
        title: '序号',
        align: 'center',
        flag: 'index',
        customRender: ({ index }) => {
          const pagination = unref(paginationRef)
          if (isBoolean(pagination) && !pagination) {
            return index + 1
          }
          const { current = 1, pageSize = table.defaultPageSize } = pagination as PaginationProps
          return ((current < 1 ? 1 : current) - 1) * pageSize + index + 1
        },
      })
    }

    const rowActions = cloneDeep(rawRowActions)
    if ((isFunction(deleteable) && deleteable()) || deleteable) {
      rowActions.push({
        label: props.value.deleteText ? props.value.deleteText : '删除',
        confirm: props.value.deleteConfirmText ? props.value.deleteConfirmText : '确定删除该记录吗？',
        click: actions.handleDel,
      })
    }
    if ((isFunction(editable) && editable()) || editable) {
      rowActions.unshift({
        label: '编辑',
        click: actions.handleEdit,
      })
    }
    // 操作列
    if (rowActions.length > 0) {

      const actionsWidth = rowActions.slice(0, 4).reduce((total, action: IGridTable.RowAction) => {
        const width = action.width ? action.width : 60

        return total + width
      }, 0)

      columns.push({
        width: actionsWidth,
        title: '操作',
        align: 'center',
        fixed: 'right',
        flag: 'action',
        customRender: ({ index }) => {
          const rawRecord = rawDataSourceRef.value[index]
          return h(RowAction, {
            actions: rowActions,
            record: rawRecord
          })
        },
      })
    }

    return columns
  })

  /* 获取ui框架所需列配置 */
  function getAntColumn(column) {
    if (column.dataIndex && isString(column.dataIndex) && column.dataIndex.indexOf('.') > 0) {
      column.dataIndex = column.dataIndex.split('.')
    }
    if (column.enumSchemas) {
      column.customRender = function ({ text }) {
        const enumItem = column.enumSchemas?.find(e => e.value.toString() === text.toString())
        return enumItem?.color ? h(Tag, { color: enumItem.color }, () => enumItem.label) : enumItem?.label
      }
    }
    if (column.referenceKey) {
      const { reference } = useAppStore()
      column.customRender = function ({ text }) {
        return reference?.[column.referenceKey as string]?.find(e => e.value === text)?.label
      }
    }
    if (column.thumbable) {
      column.customRender = function ({ text }) {
        return h(Image, {
          src: text,
          width: 120
        })
      }
    }
    if (column.children) {
      column.children = column.children.map(x => getAntColumn(x))
    }

    return column
  }

  function setColumns(columns: IGridTable.ColumnProps[]) {
    const newColumns = cloneDeep(columns)
    rawColumnsRef.value = newColumns
  }

  function getColumns(opt: IGridTable.GetColumnParam) {
    let columns = unref(allColumnsRef)
    if (opt?.ignoreHidden) {
      columns = columns.filter(column => !column.defaultHidden)
    }
    if (opt?.ignoreIndex) {
      columns = columns.filter(column => column.flag !== 'index')
    }
    if (opt?.ignoreAction) {
      columns = columns.filter(column => column.flag !== 'action')
    }

    return columns
  }

  return {
    columnsRef,
    allColumnsRef,
    setColumns,
    getColumns,
  }
}
