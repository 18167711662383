import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { Router, RouteLocationRaw } from 'vue-router'
import { useAppStore, useUserWithoutStore } from '@/store'

NProgress.configure({ showSpinner: false })

// 设置路由守卫，进入每个路由前的操作
export default function setupPermissionGuard(router: Router) {
  router.beforeEach(async (to, _, next) => {

    /* 数据 */
    const userStore = useUserWithoutStore()
    const appStore = useAppStore()
    let nextParams: RouteLocationRaw | undefined

    /* 逻辑 */
    NProgress.start()

    /* 准备全局数据 */
    if (!appStore.reference) {
      await appStore.refreshReference()
    }

    /* 准备用户数据 */
    if (!userStore.user) {
      if (to.meta.noNeedAuth !== true || to.meta.requiredPermission) {
        if (userStore.token) {
          await userStore.getUserInfo()
        } else {
          nextParams = {
            replace: true,
            name: 'login',
            query: {
              refererUrl: encodeURIComponent(to.fullPath),
            },
          }
        }
      }
    }

    /* 权限控制 */
    if (to.meta.requiredPermission) {
      if (
        userStore.user
        && !userStore.hasPermission(to.meta?.requiredPermission)
      ) {
        nextParams = { name: 'notFound' }
      }
    }

    next(nextParams as undefined)

    NProgress.done()
  })
}
