import EmptyLayout from '@/layouts/empty-layout.vue'
import { CustomRouteRecordRaw } from 'vue-router'

/* 预支相关 */
export const advanceRoutes: CustomRouteRecordRaw = {
  path: 'advance',
  name: 'advance',
  component: EmptyLayout,
  meta: {
    title: '预支管理',
    icon: 'euro-circle-outlined',
  },
  children: [

    /* 预支名单列表 */
    {
      path: 'advance-name/advance-name-list',
      name: 'advanceNameList',
      component: () =>
        import('@/views/advance/advance-name/advance-name-list.vue'),
      meta: {
        title: '预支名单列表',
        requiredPermission: 'ADVANCE_VIEW',
      },
    },
    {
      path: 'advance-name/batch-add-advance-name',
      name: 'batchAddAdvanceName',
      component: () =>
        import('@/views/advance/advance-name/batch-add-advance-name.vue'),
      meta: {
        hideInMenu: true,
        title: '批量新增预支名单',
        requiredPermission: 'ADVANCE_CREATE',
      },
    },
    {
      path: 'advance-name/add-advance-name-step1',
      name: 'addAdvanceNameStep1',
      component: () =>
        import('@/views/advance/advance-name/add-advance-name-step1.vue'),
      meta: {
        hideInMenu: true,
        title: '新增预支名单',
        requiredPermission: 'ADVANCE_CREATE',
      },
    },
    {
      path: 'advance-name/add-advance-name-step2',
      name: 'addAdvanceNameStep2',
      component: () =>
        import('@/views/advance/advance-name/add-advance-name-step2.vue'),
      meta: {
        hideInMenu: true,
        title: '新增预支名单',
        requiredPermission: 'ADVANCE_CREATE',
      },
    },

    /* 预支申请列表 */
    {
      path: 'advance-apply-list',
      name: 'advanceApplyList',
      component: () => import('@/views/advance/advance-apply-list.vue'),
      meta: {
        title: '预支申请列表',
        requiredPermission: 'ADVANCE_PAYMENT_VIEW',
      },
    },

    /* 预支审核 */
    {
      path: 'advance-audit-list',
      name: 'advanceAuditList',
      component: () => import('@/views/advance/advance-audit-list.vue'),
      meta: {
        title: '预支审核',
        requiredPermission: 'ADVANCE_AUDIT_VIEW',
      },
    },

    /* 预支放款列表 */
    {
      path: 'advance-loan/advance-loan-list',
      name: 'advanceLoanList',
      component: () =>
        import('@/views/advance/advance-loan/advance-loan-list.vue'),
      meta: {
        title: '放款审核列表',
        requiredPermission: 'ADVANCE_LOAN_VIEW',
      },
    },
    {
      path: 'advance-loan/audit-advance-loan',
      name: 'auditAdvanceLoan',
      component: () =>
        import('@/views/advance/advance-loan/audit-advance-loan.vue'),
      meta: {
        title: '放款审核',
        hideInMenu: true,
        requiredPermission: 'ADVANCE_LOAN_EDIT',
      },
    },

    /* 预支核销列表 */
    {
      path: 'advance-debt/advance-debt-name-list',
      name: 'advanceDebtNameList',
      component: () =>
        import('@/views/advance/advance-debt/advance-debt-name-list.vue'),
      meta: {
        title: '冲销还款',
        requiredPermission: 'ADVANCE_DEBT_VIEW',
      },
    },
    {
      path: 'advance-debt/advance-debt-name-detail',
      name: 'advanceDebtNameDetail',
      component: () =>
        import('@/views/advance/advance-debt/advance-debt-name-detail.vue'),
      meta: {
        title: '冲销详情',
        requiredPermission: 'ADVANCE_DEBT_VIEW',
        hideInMenu: true,
      },
    },

    /* 预支设置 */
    {
      path: 'advance-setting/advance-setting-list',
      name: 'advanceSettingList',
      component: () =>
        import('@/views/advance/advance-setting/advance-setting-list.vue'),
      meta: {
        title: '预支设置',
        requiredPermission: 'ADVANCE_CONFIG_VIEW',
      },
    },
    {
      path: 'advance-setting/advance-setting-form',
      name: 'advanceSettingForm',
      component: () =>
        import('@/views/advance/advance-setting/advance-setting-form.vue'),
      meta: {
        requiredPermission: 'ADVANCE_CONFIG_EDIT',
        hideInMenu: true,
      },
      beforeEnter: to => {
        if (to.query.advanceSettingId) {
          to.meta.title = '编辑预支模式'
        } else {
          to.meta.title = '新增预支模式'
        }
      },
    },
  ],
}
