import { CustomRouteRecordRaw } from 'vue-router'

/* 未分类 */
export const certificatesRoutes: CustomRouteRecordRaw = {
  path: 'other/certificates-list',
  name: 'certificatesList',
  meta: {
    title: '员工证件列表',
    icon: 'fund-outlined',
  },
  component: () => import('@/views/other/certificates-list.vue'),
}

export const compressFileRoutes: CustomRouteRecordRaw = {
  path: 'other/compress-file-list',
  name: 'compressFileList',
  meta: {
    title: '数据导出列表',
    icon: 'fund-outlined',
  },
  component: () => import('@/views/other/compress-file-list.vue'),
}
