import { App } from 'vue'
import { jsDebugNotify, jsDebugNotifyError, jsDebugTest, setupJsFundebug } from './js-debug'

export const appEnv = 'WEB'
export const setupFundebug = (app: App) => {
  if (appEnv === 'WEB') {
    setupJsFundebug(app)
  }
}

export const debugNotify = (name: string, message: string, option?: Recordable) => {
  if (appEnv === 'WEB') {
    jsDebugNotify(name, message, option)
  }
}
export const debugNotifyError = (option = {}, error = new Error(), name?: string) => {
  if (appEnv === 'WEB') {
    jsDebugNotifyError(option, error)
  }
}
export const debugTest = (name: string, message: string) => {
  if (appEnv === 'WEB') {
    jsDebugTest(name, message)
  }
}