import EmptyLayout from '@/layouts/empty-layout.vue'
import { CustomRouteRecordRaw } from 'vue-router'

export const systemPayRoutes: CustomRouteRecordRaw = {
  path: 'system-pay',
  name: 'systemPay',
  component: EmptyLayout,
  meta: {
    title: '系统发薪',
    icon: 'dollar-outlined',
    parentNameForBreadcrumb: 'systemPayList',
    requiredPermission: 'SYSTEM_BILL_VIEW',
  },
  children: [
    // 系统发薪
    {
      path: 'system-pay-list',
      name: 'systemPayList',
      component: () => import('@/views/system-pay/system-pay-list.vue'),
      meta: {
        title: '系统发薪',
        requiredPermission: 'SYSTEM_BILL_VIEW'
      },
    },
    // 系统发薪账单明细列表
    {
      path: 'system-pay-record',
      name: 'systemPayRecord',
      component: () =>
        import('@/views/system-pay/system-pay-record.vue'),
      meta: {
        title: '账单列表',
        requiredPermission: 'SYSTEM_BILL_DETAIL_VIEW'
      },
    },
    // 工资单列表
    {
      path: 'system-pay-payroll-List',
      name: 'systemPayPayrollList',
      component: () =>
        import('@/views/system-pay-payroll/system-pay-payroll-List.vue'),
      meta: {
        title: '工资单列表',
        requiredPermission: 'PAYROLL_VIEW'
      },
    },
    // 上传工资单
    {
      path: 'system-pay-payroll-create',
      name: 'systemPayPayrollCreate',
      component: () =>
        import('@/views/system-pay-payroll/add-system-pay-payroll.vue'),
      meta: {
        hideInMenu: true,
        title: '上传工资单',
        parentNameForBreadcrumb: 'systemPayPayrollList',
      },
    },
    // 工资单详细
    {
      path: 'system-pay-payroll-detail/:id',
      name: 'systemPayPayrollDetail',
      component: () =>
        import('@/views/system-pay-payroll/system-pay-payroll-detail.vue'),
      meta: {
        hideInMenu: true,
        title: '工资单详情',
        parentNameForBreadcrumb: 'systemPayPayrollList',
      },
    },
    // 上传发放名单
    {
      path: 'system-pay-create',
      name: 'systemPayCreate',
      component: () => import('@/views/system-pay/add-system-pay.vue'),
      meta: {
        hideInMenu: true,
        title: '上传发放名单',
        parentNameForBreadcrumb: 'systemPayList',
      },
      props: true,
    },
    // 系统发薪详细
    {
      path: 'system-pay-detail/:id',
      name: 'systemPayDetail',
      component: () => import('@/views/system-pay/system-pay-detail.vue'),
      meta: {
        hideInMenu: true,
        title: '发薪详情',
        parentNameForBreadcrumb: 'systemPayList',
      },
      props: true,

    }
  ],
}
