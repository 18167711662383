import EmptyLayout from '@/layouts/empty-layout.vue'
import { CustomRouteRecordRaw } from 'vue-router'

export const contractRoutes: CustomRouteRecordRaw = {
  path: 'contract',
  name: 'contract',
  component: EmptyLayout,
  meta: {
    title: '合同管理',
    iconfont: 'icon-icon_seal-10',
    requiredPermission: 'CONTRACT_MODEL',
  },
  children: [
    // 合同模板列表
    {
      path: 'contract-template-list',
      name: 'contractTemplateList',
      component: () => import('@/views/contract/contract-template-list.vue'),
      meta: {
        requiredPermission: 'CONTRACT_TEMPLATE_VIEW',
        title: '合同模板',
      },
    },
    // 创建合同
    {
      path: 'contract-form',
      name: 'contractForm',
      component: () => import('@/views/contract/contract-form.vue'),
      meta: {
        title: '编辑合同',
        hideInMenu: true,
      },
      beforeEnter: to => {
        if (to.query.contractId) {
          to.meta.title = '编辑合同'
        } else {
          to.meta.title = '新增合同'
        }
      }
    },
    // 合同签署列表
    {
      path: 'contract-list',
      name: 'contractList',
      component: () => import('@/views/contract/contract-list.vue'),
      meta: {
        requiredPermission: 'CONTRACT_VIEW',
        title: '合同签署列表',
      },
    },
    // 合同签署详情
    {
      path: 'contract-detail/:contractId',
      name: 'contractDetail',
      component: () => import('@/views/contract/contract-detail.vue'),
      meta: {
        title: '合同签署详情',
        hideInMenu: true,
      },
    },
  ],
}
