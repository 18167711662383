import FundebugVue from 'fundebug-vue'
import * as fundebug from 'fundebug-javascript'
import { App } from 'vue'
import { useEnv } from '@/hooks/env'

/* fundebug 小程序异常监控 */
export function setupJsFundebug(app: App) {
  fundebug.init({
    apikey: 'e155fc1884987a1f64bf8a4cbfbde500ece74551ca83224fc9267986f9b89e10',
    releasestage: useEnv.appEnv,
    monitorHttpBody: true,
    monitorHttpResponse: true,
    silentHttp: true,
    silentConsole: true,
    silentPromise: true, // 生效的
    silentResource: true,
    filters: [
      // 忽略特定错误
      // { // 因数据量太大，不收集 reference 接口数据
      //   req: {
      //     method: /^GET$/,
      //     url: /global\/reference/
      //   },
      //   // hack 此处存在一个坑，在过滤接口请求时，需要结合 req 及 res 两个参数同时使用，否则过滤效果无效
      //   res: {
      //     status: /^200$/
      //   }
      // },
      {
        message: new RegExp([

          /* ant-design 内部错误项信息，可忽略 https://github.com/ant-design/ant-design/issues/27359 */
          // 'ResizeObserver loop completed with undelivered notifications',

          /* ant-design 内部错误项信息，可忽略 https://github.com/ant-design/ant-design/issues/15075
          https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded  */
          '(ResizeObserver loop limit exceeded)',
          '(用户名不存在)',
          '(密码错误)',
        ].join('|')),
      },
      // {
      //   req: {
      //     method: /^GET$/
      //   },
      //   res: {
      //     status: /^401$/
      //   }
      // },
      // {
      //   req: {
      //     method: /^POST$/
      //   },
      //   res: {
      //     status: /^401$/
      //   }
      // }
    ],
    callback: (...params) => {},
  })
  app.use(new FundebugVue(fundebug))
}

/* 主要用于测试，它发送的错误每次都会报警邮件 */
export const jsDebugTest = (name: string, message: string) => {
  fundebug.test(name, message)
}

/* 会有执行次数限制, 大概4s一次 */
export const jsDebugNotify = (
  name: string,
  message: string,
  option?: Recordable
) => {
  fundebug.notify(name, message, {
    metaData: option,
  })
}

/* 接收抛出异常, 也可代替notify */
export const jsDebugNotifyError = (
  option: Recordable = {},
  error: Error = new Error()
) => {
  fundebug.notifyError(error, {
    metaData: option,
  })
}
