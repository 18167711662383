<template>
  <a-config-provider
    :locale="zhCN"
    :transform-cell-text="({ text }) => text === undefined || text === null || text?.length === 0 ? '-' : text"
  >
    <div class="text-gray-1000">
      <modal-form
        v-if="globalModelForm._isRender"
        v-bind="(globalModelForm.modelFormAttr as Readonly<ExtractPropTypes<typeof modalFormProps>>)"
        :ref="el => globalModelForm.modelFormRef = el"
        :visible="globalModelForm.visible"
        @ok="() => globalModelForm.visible = false"
        @cancel="() => globalModelForm.visible = false"
      />
      <excel-import-modal
        v-bind="excelImportModel.excelModelAttr"
        :visible="excelImportModel.visible"
      />
      <router-view />
    </div>
  </a-config-provider>
</template>


<script lang="ts" setup>
// eslint-disable-next-line no-duplicate-imports
import type { ExtractPropTypes } from 'vue'
import { modalFormProps } from '@/components/form/props'
import zhCN from 'ant-design-vue/es/locale/zh_CN'
import ModalForm from './components/form/modal-form.vue'
import globalModelForm from '@/hooks/global-model-form'
import excelImportModel from '@/hooks/global-excel-import'

console.log('setup')
</script>
