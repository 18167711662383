import 'polyfill-object.fromentries' // 浏览器版本问题导致Object.fromEntries is not a function的问题

import App from './app.vue'
import { createApp } from 'vue'
import { setupRouter } from './router'
import { setupStore } from './store'
import './styles/app.less'
import { setupComponent } from './components/setup-component'
import { setupFundebug } from './utils/debug'
const app = createApp(App)

setupComponent(app)
setupStore(app)
setupRouter(app)
setupFundebug(app)
app.mount('#app')
