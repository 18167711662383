import { useAppStore } from './app'
import { defineStore } from 'pinia'
import { authApi } from '@/api/auth'
import {
  setItem,
  getItem,
  removeItem,
  STORAGE_KEY_TOKEN,
} from '@/utils/storage'
import { router } from '@/router'

interface UserState {
  token: string | null; // 接口调用权限
  user: Nullable<Model.User & CustomModel.User>; // 用户信息
  permissions: Nullable<string[]>; // 权限列表
  client: Model.Client | null; // 所属商户详细信息
}

export const useUserStore = defineStore('User', {
  state: (): UserState => ({
    token: getItem(STORAGE_KEY_TOKEN),
    permissions: getItem('permissions'),
    user: null,
    client: null,
  }),
  actions: {
    // 登录
    async loginSuccess(token: string) {
      setItem(STORAGE_KEY_TOKEN, token)
      this.token = token
      await this.afterLogin()
    },

    // 登录后的处理逻辑，统一获得 token 后需要操作的事情，token 有效期间，重新进入系统获取全局配置
    async afterLogin() {
      authApi.checkToken({ withoutCheck: true })
      await Promise.all([
        this.getUserInfo(), // 获取用户信息及全局配置
        useAppStore().refreshReference(),
      ])
    },

    // 获取用户信息
    async getUserInfo() {
      const res = await authApi.getUserInfo()
      this.user = res.admin
      this.client = res.client
      this.permissions
        = res.admin?.role.permissions.map(permission => permission.toUpperCase() as Permission) || []
      setItem(
        'permissions',
        res.admin?.role.permissions.map(permission => permission.toUpperCase() as Permission) || []
      )
    },

    // 登出
    async logout(requestApi?: boolean, refererPath?: string) {
      requestApi && (await authApi.logout())
      this.token = null
      removeItem(STORAGE_KEY_TOKEN)
      router.replace({
        name: 'login',
        query: {
          refererUrl: refererPath ? encodeURIComponent(refererPath) : '',
        },
      })
    },

    hasPermission(requirePermission: Permission | undefined): boolean {
      if (
        requirePermission === undefined
        || this.permissions?.includes('ALL')
      ) {
        return true
      }
      return Boolean(this.permissions?.includes(requirePermission))
    },
  },
})
