import EmptyLayout from '@/layouts/empty-layout.vue'
import { CustomRouteRecordRaw } from 'vue-router'

export const versionRoutes: CustomRouteRecordRaw = {
  path: 'version',
  name: 'version',
  component: EmptyLayout,
  meta: {
    title: '版本管理',
    icon: 'laptop-outlined',
    hideInMenu: true,
  },
  children: [
    // 广告管理
    {
      path: 'app-version-list',
      name: 'appVersionList',
      component: () => import('@/views/version/app-version-list.vue'),
      meta: {
        title: 'app管理',
        requiredPermission: 'ALL',
      },
    },
    {
      path: 'pc-version-list',
      name: 'pcVersionList',
      component: () => import('@/views/version/pc-version-list.vue'),
      meta: {
        title: 'pc管理',
        requiredPermission: 'ALL',
      },
    },
    {
      path: 'we-app-version-list',
      name: 'wxAppVersionList',
      component: () => import('@/views/version/we-app-version-list.vue'),
      meta: {
        title: '小程序管理',
        requiredPermission: 'ALL',
      },
    },
  ],
}
