<style>
.upload-box .ant-upload.ant-upload-select {
  display: block;
}
</style>

<template>
  <div v-if="uploadDetail.fileList.length">
    <div class="border border-solid border-gray-400 pb-2 px-2 text-left">
      <a-upload
        v-if="uploadDetail.fileList"
        :file-list="uploadDetail.fileList"
        @remove="remove"
      />
    </div>
    <div class="mt-2 flex justify-between items-center h-10">
      <div class="font-bold">
        {{
          `检测结果：共检索到${tableProps.dataSource?.length}人, ${
            tableProps.dataSource?.filter((record) => !record.error).length
          }人正常，`
        }}
        <span>
          {{
            `${
              tableProps.dataSource?.filter((record) => record.error).length
            }人`
          }}
        </span>
        {{ `有异常` }}
      </div>
      <a-button
        v-if="tableProps.dataSource?.filter((data) => data.error).length"
        @click="exportErrorData"
      >
        导出错误数据
      </a-button>
    </div>
    <grid-table v-bind="tableProps" />
  </div>
  <div
    v-else
    class="upload-box"
  >
    <a-upload
      accept=".xlsx,xls"
      :action="useEnv.uploadApiUrl"
      :show-upload-list="false"
      @change="changeUpload"
    >
      <div
        class="flex flex-col justify-center p-4 border border-solid border-gray-400 text-common-theme text-center"
        style="height: 264px"
      >
        <basic-icon
          name="icon-icon_xlsx"
          style="font-size: 66px"
        />
        <div class="mt-5">
          <a-button type="primary">
            点击上传文件
          </a-button>
        </div>
        <div class="mt-2 text-xs">
          <div>可点击此处或者拖拽文件到此上传</div>
          <div class="mt-2">
            支持上传Excel文件，且大小不超过2M
          </div>
        </div>
      </div>
    </a-upload>
  </div>
</template>

<script lang="ts" setup>
// import {Upload} from 'ant-design-vue'
import { UploadChangeParam, UploadFile } from 'ant-design-vue'
import { useEnv } from '@/hooks/env'
import { Validator } from '@/utils/validator'
import { getExcelData, aoaToSheetXlsx } from '@/utils/xlsx'
import { reactive } from 'vue'
import { excelImportOfFileProps } from './props'
import { cloneDeep, get } from 'lodash-es'
import { parseDotStrObjToObj } from '@/utils/object'
import { useMessage } from '@/hooks/message'

/* types 类型注释 */
interface UploadDetail {
  fileList: UploadFile[];
  baseErrorDataSource: Recordable[];
  elseDataSource: Recordable[];
}

/* data 数据 */
const emits = defineEmits(['update:value'])
const props = defineProps(excelImportOfFileProps)

/* 上传详情 */
const uploadDetail = reactive<UploadDetail>({
  fileList: [],
  baseErrorDataSource: [],
  elseDataSource: [],
})
const tableProps = reactive<IGridTable.Props>({
  columnSchemas: [],
  dataSource: [],
  headerIsShow: false,
  pagination: {
    pageSize: 999,
  },
})

/* methods 方法 */
function remove() {
  uploadDetail.fileList = []
  emits('update:value', '')
}

/*  */
async function exportErrorData() {
  if (tableProps.columnSchemas && tableProps.dataSource) {
    await aoaToSheetXlsx({
      header: tableProps.columnSchemas?.map(item => item.title) as string[],
      data: tableProps.dataSource
        ?.filter(data => data.error)
        .map(data =>
          tableProps.columnSchemas?.map(columnSchema =>
            get(data, columnSchema.dataIndex as string))) as string[][],
      filename: '错误数据.xlsx',
    })
    emits(
      'update:value',
      uploadDetail.elseDataSource.filter(data => !data.error)
    )
  }
}

/* 上传文件 */
async function changeUpload(uploadChangeParam: UploadChangeParam) {
  const { file } = uploadChangeParam
  if (file.status === 'done') {
    const excelData = await getExcelData(file.originFileObj as File)
    const headers = excelData[0].headers
    const results = excelData[0].results
    const { labelMap, rules } = props
    const newRules: Recordable = cloneDeep(rules)
    const attachInfo: Reference.ReferenceItem[] = []

    /* 获取值映射 和 新规则 */
    const newLabelMap: Recordable = {
      异常原因: 'error',
    }

    /* 设置新规则 */
    for (const key in newRules) {
      const header = headers.find(item => item.indexOf(key) !== -1)
      if (header) {
        newRules[header] = newRules[key]
        delete newRules[key]
      } else if (newRules[key][0].required) {
        useMessage.error('上传文件不正确，请重新下载编辑再上传')
        throw Error('上传文件不正确，请重新下载编辑再上传')
      }
    }
    if (!results.length) {
      useMessage.error('上传文件为空，请编辑后再上传')
      throw Error('上传文件为空，请编辑后再上传')
    }

    headers.forEach((header, index) => {

      /* 获取值映射 */
      const labelItem = Object.keys(labelMap).find(label => header.indexOf(label) !== -1)
      if (labelItem) {
        newLabelMap[header] = labelMap[labelItem]
      } else if (header !== '异常原因') {
        attachInfo.push({
          label: header,
          value: `attaches.${attachInfo.length}`,
        })
        newLabelMap[header] = `attaches.${attachInfo.length - 1}`
      }
    })

    /* 校验 */
    results.forEach((result, index) => {
      const validator = new Validator(result, newRules)

      const messages = validator.validate()
      result['异常原因'] = messages.join('、')
    })

    /* 获取and表格数据 */
    const columnSchemas = headers.map((header, index) => ({
      dataIndex: newLabelMap[header],
      title: header,
    }))

    tableProps.columnSchemas = [
      ...columnSchemas,
      {
        dataIndex: 'error',
        title: '异常原因',
        width: 200,
        fixed: 'right',
        customRender: ({ text }) =>
          h(
            'div',
            {
              class: 'text-red-400',
            },
            text
          ),
      },
    ]
    const dataSource = results.map(result => {
      const newResult: Recordable = {}
      for (const label in result) {
        newResult[newLabelMap[label]] = result[label]
      }
      return parseDotStrObjToObj(newResult)
    })

    const { response } = file
    uploadDetail.baseErrorDataSource = dataSource!.filter(record => record.error)
    uploadDetail.elseDataSource = dataSource!.filter(record => !record.error)
    tableProps.dataSource = [
      ...uploadDetail.baseErrorDataSource,
      ...uploadDetail.elseDataSource,
    ]
    uploadDetail.fileList = [file]

    if (
      !props.checkApi
      && !tableProps.dataSource?.filter(data => data.error).length
    ) {
      emits('update:value', response.data.path)
    }

    if (props.checkApi) {
      const elseDataSource = await props.checkApi(response.data.path)
      uploadDetail.elseDataSource = elseDataSource.sort((a, b) => {
        if (!a.error && b.error) {
          return 1
        } else {
          return -1
        }
      })
      tableProps.dataSource = [
        ...uploadDetail.baseErrorDataSource,
        ...uploadDetail.elseDataSource,
      ]
      if (!uploadDetail.elseDataSource?.filter(data => data.error).length) {
        emits('update:value', response.data.path)
      }
    }
  }
}
</script>
