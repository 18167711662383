import { ExtractPropTypes, PropType } from 'vue'
import type { Rule } from '@/utils/Validator'

export const excelImportOfFileProps = {
  // 双向数据绑定的该组件的值
  value: {
    type: Array as PropType<Recordable[]>,
    default: () => [],
  },
  labelMap: {
    type: Object as PropType<Recordable>,
    default: () => ({}),
    required: true,
  },
  rules: {
    type: Object as PropType<Recordable<Rule[]>>,
    default: () => ({}),
  },
  checkApi: {
    type: Function,
    default: () => {},
  },
}

export type ExcelImportOfFileProps = Partial<
  ExtractPropTypes<typeof excelImportOfFileProps>
>;
