import EmptyLayout from '@/layouts/empty-layout.vue'
import { CustomRouteRecordRaw } from 'vue-router'

export const marketingRoutes: CustomRouteRecordRaw = {
  path: 'marketing',
  name: 'marketing',
  component: EmptyLayout,
  meta: {
    title: '营销中心',
    iconfont: 'icon-store-2-fill',
    requiredPermission: 'ALL',
  },
  children: [
    {
      path: 'activity/activity-list',
      name: 'activityList',
      component: () => import('@/views/marketing/activity/activity-list.vue'),
      meta: {
        title: '活动列表',
      },
    },
    {
      path: 'withdrawal-audit/withdrawal-audit-list',
      name: 'withdrawalAuditList',
      component: () =>
        import('@/views/marketing/withdrawal-audit/withdrawal-audit-list.vue'),
      meta: {
        title: '提现审核',
      },
    },
    {
      path: 'withdrawal-audit/withdrawal-log-list',
      name: 'withdrawalLogList',
      component: () =>
        import('@/views/marketing/withdrawal-audit/withdrawal-log-list.vue'),
      meta: {
        title: '提现记录',
      },
    },
    {
      path: 'agent-list',
      name: 'agentList',
      component: () => import('@/views/marketing/agent-list.vue'),
      meta: {
        title: '代理人管理',
      },
    },
    {
      path: 'activity/inviter-list',
      name: 'inviterList',
      component: () => import('@/views/marketing/activity/inviter-list.vue'),
      meta: {
        title: '代理邀请记录',
      },
    },
    {
      path: 'activity/share-promotion-data',
      name: 'sharePromotionData',
      component: () =>
        import('@/views/marketing/activity/share-promotion-data.vue'),
      meta: {
        title: '推广数据',
        hideInMenu: true,
      },
    },
    {
      path: 'activity/sign-in-promotion-data',
      name: 'signInPromotionData',
      component: () =>
        import('@/views/marketing/activity/sign-in-promotion-data.vue'),
      meta: {
        title: '推广数据',
        hideInMenu: true,
      },
    },
    {
      path: 'activity/add-agent-activity',
      name: 'addAgentActivity',
      component: () =>
        import('@/views/marketing/activity/add-agent-activity.vue'),
      meta: {
        title: '活动设置',
        hideInMenu: true,
      },
    },
    {
      path: 'activity/add-share-activity',
      name: 'addShareActivity',
      component: () =>
        import('@/views/marketing/activity/add-share-activity.vue'),
      meta: {
        title: '新建分享红包',
        hideInMenu: true,
      },
    },
    {
      path: 'activity/add-sign-in-activity',
      name: 'addSignInActivity',
      component: () =>
        import('@/views/marketing/activity/add-sign-in-activity.vue'),
      meta: {
        title: '新建签到红包',
        hideInMenu: true,
      },
    },
    {
      path: 'activity/activity-black-list',
      name: 'activityBlackList',
      component: () =>
        import('@/views/marketing/activity/activity-blacklist.vue'),
      meta: {
        title: '黑名单管理',
      },
    },
  ],
}
