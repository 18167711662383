<template>
  <div
    class="h-full p-4"
    style="min-width: 1000px;"
  >
    <router-view :key="routerViewKey" />
  </div>
</template>
<script setup lang="ts">
import { emitter, FORCE_REFRESH_ROUTE } from '@/mitt/global-event'
const routerViewKey = ref(new Date().getTime())

// 监听路由强制刷新事件，为路由 key 重新赋值，触发组件的重新渲染
emitter.on(FORCE_REFRESH_ROUTE, () => {
  // 因为 route 变更在下一个宏任务中才能完成，无法通过 await nextTick 推迟微任务监听到，故使用 setTimeout 之后再进行刷新
  setTimeout(() => {
    routerViewKey.value = new Date().getTime()
  })
})

</script>