import { defineStore } from 'pinia'
import { globalApi } from '@/api/global'

interface ReferenceColorMap {
  green: string;
  orange: string;
  red: string;
}

interface GlobalState {

  /* 枚举类型 */
  reference: Nullable<
    Partial<{ [key in Reference.ReferenceKey]: Reference.ReferenceItem[] }>
  >;
  referenceColorMap: ReferenceColorMap;
}

/* app 全局信息 */
export const useAppStore = defineStore('App', {
  state: (): GlobalState => ({
    reference: null,

    /* reference 中配置的颜色转换器 */
    referenceColorMap: {
      green: '#00B42A',
      orange: '#FF9A2E',
      red: '#F53F3F',
    },
  }),

  actions: {

    /* 获取枚举值列表 */
    getReferenceItems(referenceKey: Reference.ReferenceKey): Reference.ReferenceItem[] {
      return this.reference?.[referenceKey] || []
    },

    /* 获取枚举值的值映射列表 */
    getReferenceItemsValueMap(referenceKey: Reference.ReferenceKey): Record<number, Reference.ReferenceItem> {
      let valueMap: Record<number, Reference.ReferenceItem> = {}
      this.getReferenceItems(referenceKey).forEach(item => {
        valueMap[item.value] = toRaw(item)
      })
      return valueMap
    },

    /* 获取枚举值的值映射列表 */
    getReferenceItemsLabelMap(referenceKey: Reference.ReferenceKey): Record<string, Reference.ReferenceItem> {
      let labelMap: Record<string, Reference.ReferenceItem> = {}
      this.getReferenceItems(referenceKey).forEach(item => {
        labelMap[item.label] = toRaw(item)
      })
      return labelMap
    },

    /* 获取枚举值常量 map */
    getReferenceConstantMap<T extends Reference.ReferenceKey>(referenceKey: T): Reference.ConstantKeyMap[T] {
      let constantMap = {}
      this.getReferenceItems(referenceKey).forEach(item => {
        constantMap[item.key!] = toRaw(item)
      })
      return constantMap as Reference.ConstantKeyMap[T]
    },

    /* 重置枚举值 */
    async refreshReference() {
      this.reference = await globalApi.getReference()

      /* 设置枚举颜色值 */
      const EnableStatuses = this.getReferenceConstantMap<'enableStatuses'>('enableStatuses')
      EnableStatuses.DISABLE.color = 'red'
      EnableStatuses.ENABLE.color = 'green'

      const issueStatusesConstantMap = this.getReferenceConstantMap<'issueStatuses'>('issueStatuses')
      issueStatusesConstantMap.ISSUE_FAIL.color = this.referenceColorMap.red
      issueStatusesConstantMap.ISSUE_SUCCESS.color = this.referenceColorMap.green
      issueStatusesConstantMap.ISSUING.color = this.referenceColorMap.orange
      issueStatusesConstantMap.WAITING.color = this.referenceColorMap.orange

      const BillStatuses = this.getReferenceConstantMap<'billStatuses'>('billStatuses')
      BillStatuses.REJECTED.color = 'red'
      BillStatuses.PAY_FAILURE.color = 'red'
      BillStatuses.CANCELED.color = 'red'
      BillStatuses.TO_SUBMIT.color = 'orange'
      BillStatuses.TO_AUDIT.color = 'orange'
      BillStatuses.PAYING.color = 'orange'
      BillStatuses.PAY_DONE.color = 'green'
    },
  },
})
