import { CustomRouteRecordRaw } from 'vue-router'

export const workbenchRoutes: CustomRouteRecordRaw = {
  path: 'workbench',
  name: 'workbench',
  meta: {
    title: '工作台',
    icon: 'fund-outlined'
  },
  component: () => import('@/views/workbench/workbench.vue'),
}