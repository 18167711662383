<template>
  <a-select
    v-bind="bindAttrs"
    @dropdownVisibleChange="handleFetch"
  >
    <template
      v-if="loading"
      #suffixIcon
    >
      <loading-outlined spin />
    </template>
    <template
      v-if="loading"
      #notFoundContent
    >
      <span>
        <loading-outlined
          spin
          class="mr-1"
        />
        暂无数据
      </span>
    </template>
  </a-select>
</template>
<script lang="ts" setup>
import { computed, unref, useAttrs } from 'vue'
import { get, isFunction, isArray, debounce, uniqBy } from 'lodash-es'

import { customApiSelectProps } from './props'

const props = defineProps(customApiSelectProps)
type EmitEvents = {
  (e: 'change', ...value): void
  (e: 'update:value', ...value): void
  (e: 'options-change', options: Recordable[]): void
}
const emits = defineEmits<EmitEvents>()
const attrs = useAttrs()
const loading = ref(false)
const isFetched = ref(false)
const options = ref<Recordable[]>([])

const value = computed(() => {
  // 首次进入，options 为空，但 props.value 有值，则初始化 options，实现数据回填时的显示功能
  if (options.value.length === 0 && props.value) {
    fetch({ valueIds: isArray(props.value) ? props.value : [props.value] })
  }
  return (props.value === 0 && props.zeroToUndefined ? undefined : props.value)
})

onMounted(() => {
  props.immediate && fetch()
})
defineExpose({
  fetch,
})


async function handleFetch() {
  if (!isFetched.value) {
    await fetch()
  }
}

async function fetch(searchParams: Recordable = {}) {

  const { api, apiParams, resultField, labelField, valueField, numberToString } = props
  if (!api || !isFunction(api)) {
    return
  }

  loading.value = true
  const res = await api(Object.assign({}, apiParams, searchParams))
  let optionData = isArray(res) ? res : get(res, resultField)

  // 保留已经选中的 options 项目
  const values = isArray(value.value) ? value.value : [value.value]
  const selectedOptions = options.value.filter(option => values.includes(option.value))
  options.value = optionData?.map(item => {
    const optionValue = item[valueField]
    return {
      label: isFunction(labelField) ? labelField(item) : item[labelField as string],
      value: numberToString ? `${optionValue}` : optionValue,
    }
  })
  // 数组去重
  options.value = uniqBy([...options.value, ...selectedOptions], item => item.value)

  isFetched.value = true
  emits('options-change', options.value)
  loading.value = false
}

const onSearch = debounce(value => {
  const { searchKey } = props
  fetch({ [searchKey]: value })
}, 300)


const bindAttrs = computed(() => ({
  dropdownStyle: { maxHeight: '280px' },
  optionFilterProp: 'label',
  showSearch: true,
  value: unref(value) ? unref(value) : undefined,
  options: unref(options),
  onSearch: props.searchKey ? onSearch : () => { },
  onChange: (...newValue) => emits('change', ...newValue),
  'onUpdate:value': (...newValue) => emits('update:value', ...newValue),
  ...attrs,
}))
</script>
