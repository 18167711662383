import type { CustomRouteRecordRaw } from 'vue-router'
import { personalEndRoutes } from './personal-end'
import { versionRoutes } from './version'
import { clientRoutes } from './client'
import { payRoutes } from './self-pay'
import { systemPayRoutes } from './system-pay'
import { paymentRoutes } from './payment'
import { workerRoutes } from './worker'
import { contractRoutes } from './contract'
import { useSiteConfig } from '@/hooks/site-config'
import BackendLayout from '@/layouts/backend-layout.vue'
import { exampleRoutes } from './example'
import { workbenchRoutes } from './workbench'
import { advanceRoutes } from './advance'
import { marketingRoutes } from './marketing'
// import { codeRoutes } from './code'
import { certificatesRoutes, compressFileRoutes } from './other'
const { backendPrefix } = useSiteConfig

export const appRoutes: CustomRouteRecordRaw[] = [
  workbenchRoutes,
  paymentRoutes,
  payRoutes,
  systemPayRoutes,
  advanceRoutes,
  workerRoutes,
  contractRoutes,
  clientRoutes,
  personalEndRoutes,
  marketingRoutes,
  versionRoutes,
  certificatesRoutes,
  compressFileRoutes,
  // codeRoutes,
  exampleRoutes,
]

const routes: CustomRouteRecordRaw[] = [
  {
    path: '/',
    name: 'officialWeb',
    component: () => import('@/views/official-web.vue'),
    meta: {
      title: '官网',
      noNeedAuth: true,
    },
  },
  {
    path: '/test-center',
    name: 'testCenter',
    component: () => import('@/views/test-center.vue'),
    meta: {
      title: '测试中心',
      noNeedAuth: true,
    },
  },
  {
    path: `${backendPrefix}/login`,
    name: 'login',
    component: () => import('@/views/auth/login/login.vue'),
    meta: {
      title: '账号登录',
      noNeedAuth: true,
    },
  },
  {
    path: backendPrefix,
    name: 'root',
    component: BackendLayout,
    redirect: { name: 'workbench' },
    // meta: {
    //   noNeedAuth: true,
    // },
    children: appRoutes,
  },
  {
    path: '/*',
    // path: '/:patchMatch(.*)*',
    name: 'notFound',
    component: () => import('@/views/404.vue'),
    meta: {
      title: '页面不存在',
      noNeedAuth: true,
    },
  },
]
// 获取父路由的名字
function setParentName(routeList, parentNameList) {
  routeList.forEach(route => {
    route.meta = route.meta || {}
    route.meta.parentName = parentNameList
    route.children?.length
      && setParentName(route.children, [...parentNameList, route.name])
  })
}
setParentName(routes, [])

export default routes
