import { BasicModalProps } from '@/components/modal/prop'

/* 类型注释 */
interface Rule {
  required?: boolean;
  pattern?: string;
  message: string;
}
type ExcelModeProps = {
  modalProps: BasicModalProps;
  textKeyMap: Recordable;
  fileUrl: string,
  templateName: string,
  rules?: Record<string, Rule[]>;
  checkApi: Fn;
  importApi: Fn;
  transformCheckRecords?: Fn;
}

// 默认模态框设置
const defaultModalProp: BasicModalProps = {
  width: 620,
  okText: '检查并提交',
  cancelText: '取消',
  okType: 'primary',
}

// 模态框显示与隐藏
const visible = ref(false)


const excelModelAttr = ref() as Ref<ExcelModeProps>

// 初始化模态框，即显示模态框
async function init(props: ExcelModeProps) {

  excelModelAttr.value = {
    textKeyMap: props.textKeyMap,
    fileUrl: props.fileUrl,
    templateName: props.templateName,
    checkApi: props.checkApi,
    importApi: props.importApi,
    transformCheckRecords: props.transformCheckRecords,
    rules: props.rules,
    modalProps: Object.assign({}, defaultModalProp, props.modalProps),
  }

  await nextTick()
  visible.value = true
}


/** 全局模态框实例 */
const excelImportModel = reactive({

  /** 全局模态框初始化函数 */
  init,

  /** 模态框的属性配置 */
  excelModelAttr,

  /** 模态框显示控制 */
  visible,
})


export default excelImportModel


