<style lang='less' scoped>
  .dateBox{
width: 300px;
border: 1px solid rgb(230, 221, 221);
border-radius: 5px;
.title{
  font-weight: 700;
  line-height: 35px;

    .Fk{
      display: inline-block;
     height: 10px;
     width: 10px;

     background-color: #ffffffd3;
     border: 1px solid rgb(230, 221, 221);
    }
    .FkActive{

     background-color: #188fffd3;

    }
}
    .content{
      padding: 5px;
      .dateItem{
        font-weight: 600;
        cursor: pointer;
        border-radius: 4px;
        text-align: center;
        width: 30px;
        border: 1px solid rgb(230, 221, 221);
        display: inline-block;
        margin: 5px 5px;
        transition: .2s;
        &:hover{
         box-shadow: 0 0 5px 0 rgb(186, 181, 181);
          scale: 1.1;
          transition: .2s;
        }
      }
      .active {transition: .2s;
        color: white;
          background-color: #188fffd3;
        }
    }
  }
</style>

<template>
  <div class="dateBox">
    <div class="text-center relative title">
      每月 <div
        class="absolute  right-0 top-0 mr-2 cursor-pointer"
        @click="checkAll(true)"
      >
        全选
      </div>
      <div
        class="absolute  left-0 top-0 ml-2 cursor-pointer"
        @click="checkAll(false)"
      >
        全不选
      </div>
    </div>
    <div class="content">
      <div
        v-for="(item,index) in defaultDateArray"
        :key="index"
        class="dateItem"
        :class="{'active':item.checked}"
        @click="check(index)"
      >
        {{ item.label }}
      </div>
    </div>
  </div>
</template>
<script lang='ts' setup>
const isAll = ref<boolean>(false)
  type EmitEvents = {
  (e:'update:value', checkedDateArray: number[]):void
}
const emits = defineEmits<EmitEvents>()
  type DateItem = {label:string, value:number, checked:boolean}
interface DefaultDateArray {
[x: string]: any;
  [index:number]:DateItem
}
const defaultDateArray = ref([]) as Ref<DefaultDateArray>

const getCheckedDate = () => defaultDateArray.value.filter(item => item.checked)
const checkAll = (isTrue:boolean) => {
  isAll.value = isTrue
  unref(defaultDateArray).forEach((item:DateItem) => {
    item.checked = isTrue
  })
  emits('update:value', getCheckedDate().map((item:DateItem) => item.value))
}
const check = (index:keyof DefaultDateArray) => {
  defaultDateArray.value[index].checked = !defaultDateArray.value[index].checked
  emits('update:value', getCheckedDate().map((item:DateItem) => item.value))
}


for (let i = 1; i < 32; i++) {
  defaultDateArray.value.push({
    label: `${i}`,
    value: i,
    checked: false
  })
}

</script>