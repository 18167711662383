
import { computed, unref } from 'vue'
import { isNumber } from 'lodash-es'

export function useItemLabelWidth(schemaItemRef: IForm.Schema, propsRef: IForm.Props, layout: IForm.Props.layout) {
  return computed(() => {
    const schemaItem = unref(schemaItemRef)
    const { labelCol = {}, wrapperCol = {} } = schemaItem.itemProps || {}
    // const { labelWidth, disabledLabelWidth } = schemaItem

    const {
      labelWidth: globalLabelWidth,

      // labelCol: globalLabelCol,
      // wrapperCol: globWrapperCol,
    } = unref(propsRef)

    // If labelWidth is set globally, all items setting
    if ((!globalLabelWidth)) {
      labelCol.style = {
        textAlign: 'left',
      }
      return {
        labelCol,
        wrapperCol
      }
    }
    let width: any = globalLabelWidth
    const col = {
      // ...globalLabelCol,
      ...labelCol
    }
    const wrapCol = {
      // ...globWrapperCol,
      ...wrapperCol
    }

    if (width) {
      width = isNumber(width) ? `${width}px` : width
    }

    return {
      labelCol: {
        style: { width: layout === 'vertical' ? '100%' : width },
        ...col
      },
      wrapperCol: {
        // style: { width: `calc(100% - ${width})` },
        ...wrapCol
      },
    }
  })
}
