import EmptyLayout from '@/layouts/empty-layout.vue'
import { CustomRouteRecordRaw } from 'vue-router'

export const payRoutes: CustomRouteRecordRaw = {
  path: 'self-pay',
  name: 'selfPay',
  component: EmptyLayout,
  meta: {
    title: '自主发薪',
    icon: 'dollar-outlined',
    parentNameForBreadcrumb: 'selfPayList',
    requiredPermission: 'SELF_BILL_VIEW',
  },
  children: [
    // 自主发薪列表
    {
      path: 'self-pay-list',
      name: 'selfPayList',
      component: () => import('@/views/self-pay/self-pay-list.vue'),
      meta: {
        title: '自主发薪',
      },
    },
    // 自主发薪详情
    {
      path: 'self-pay-detail/:id',
      name: 'selfPayDetail',
      component: () => import('@/views/self-pay/self-pay-detail.vue'),
      meta: {
        hideInMenu: true,
        title: '发薪详情',
        parentNameForBreadcrumb: 'selfPayList',
      },
      props: true,
    },
    // 账单明细列表
    {
      path: 'self-pay-record-list',
      name: 'selfPayRecordList',
      component: () => import('@/views/self-pay/self-pay-record-list.vue'),
      meta: {
        requiredPermission: 'SELF_BILL_DETAIL_VIEW',
        title: '账单列表',
      },
    },
  ],
}
